import React from 'react';
import logo from './crane.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="Content">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Diese Webseite befindet sich aktuell im Aufbau.
          </p>
          <div>
            <p className="Inhaber">
              Inh. Sebastian Neubert
            </p>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
